<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                dense single-line hide-details
                :is="renderEditableComponent"
                v-model="formInput.inputValue"
                class="p-0 m-0"
                :type="inputType"
                :autosize="{ minRows: 2, maxRows: 8}"
                :rules="isRequired ? rules : []"
                :label="label"
                @click="cellClickHandler"
                @keydown="keydownEvent"
            >
                <slot></slot>
            </component>
        </component>
    </div>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'EditableCell',
    mixins: [ FormRules ],
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-textarea'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Enter value'
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            formInput: {inputValue: ''},
            defaultRules: [],
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
        if (!this.rules) this.rules = this.defaultRules
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return false

            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        keydownEvent(e) {
            this.$emit('keydown', e)
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value
            return text || ''
        },
        renderEditableComponent() {
            return this.editableComponent
        },
        renderForm() {
            return 'v-form'
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>
