var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-action-plan__table editable_table expandable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","show-expand":_vm.activeRowId == null && _vm.currentNewId == null,"single-expand":true,"item-key":"actionPlanId","headers":_vm.headers,"items":_vm.tableDataArr,"expanded":_vm.expanded,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"item-class":_vm.isNotRelevantClass,"data-cy":"clientList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler,"item-selected":_vm.itemSelected},scopedSlots:_vm._u([{key:"item.relevant",fn:function(ref){
var item = ref.item;
return [_c('CheckboxCell',{ref:("relevant-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.relevant),callback:function ($$v) {_vm.$set(item, "relevant", $$v)},expression:"item.relevant"}})]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('NumberInputCell',{ref:("order-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"disabled":_vm.disabled,"isRequired":true,"label":"No","rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"item.goal",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("goal-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"disabled":_vm.disabled,"isRequired":true,"label":"Goal","rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.goal),callback:function ($$v) {_vm.$set(item, "goal", $$v)},expression:"item.goal"}})]}},{key:"item.commenced",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("commenced-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.commenced),callback:function ($$v) {_vm.$set(item, "commenced", $$v)},expression:"item.commenced"}})]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("completed-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.completed),callback:function ($$v) {_vm.$set(item, "completed", $$v)},expression:"item.completed"}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":"2"}},[_vm._v(" ")]),_c('td',{staticClass:"py-2",attrs:{"colspan":"1"}},[_c('TextareaCell',{ref:("action-" + (item.actionPlanId)),attrs:{"edit-active":item.active,"cell-id":item.actionPlanId,"editable-component":'v-textarea',"disabled":_vm.disabled,"isRequired":false,"label":"Action"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.action),callback:function ($$v) {_vm.$set(item, "action", $$v)},expression:"item.action"}})],1),_c('td',{attrs:{"colspan":"4"}},[_vm._v(" ")])]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()])]}}:null,(!_vm.disabled && _vm.isAddable)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addNewActionPlan}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1)]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }